import Keycloak from 'keycloak-js';

const authStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user'
};

const endpointsStaging = {
  environment: 'staging',
  base_url: 'https://staging.services.api.viana.ai/',
  base_url_insights: 'https://staging.insights.api.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://31d3195c411247c2b975b812f9bea27c@sentry.internal.viana.ai/14'
  
};

const endpointsStaging2 = {
  environment: 'staging2',
  base_url: 'https://viana-services-staging-copy2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://31d3195c411247c2b975b812f9bea27c@sentry.internal.viana.ai/14'
};

const endpointsStaging3 = {
  environment: 'staging3',
  base_url: 'https://viana-services-staging-copy3.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://31d3195c411247c2b975b812f9bea27c@sentry.internal.viana.ai/14'
};

const endpointsVersaDev = {
  environment: 'versa_dev',
  base_url: 'https://versa-services-dev-svc.azurewebsites.net/',
  base_url_insights: 'https://versa-insights-dev-svc.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVersaStaging = {
  environment: 'versa_staging',
  base_url: 'https://versa-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://versa-insights-staging-svc.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVianaLite = {
  environment: 'viana_lite_internal',
  base_url: 'https://viana-internal-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  //sentry_dsn: 'https://0fff9f661ea047f683b1113a7fcc80bd@sentry.internal.viana.ai/8'
};

const authDev = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user'
};

const endpointsDev = {
  environment: 'dev',
  base_url: 'https://dev.services.api.viana.ai/',
  base_url_insights: 'https://dev.insights.api.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://07c256f775fd49dd9782beb8fc0adb50@sentry.internal.viana.ai/13'
};

const endpointsNode16Dev = {
  environment: 'node16_dev',
  base_url: 'https://services.node16poc.internal.viana.ai/',
  base_url_insights: 'https://insights.node16poc.internal.viana.ai/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};


const endpointsDev2 = {
  environment: 'dev2',
  base_url: 'https://viana-dev-copy2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev.azurewebsites.net/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsDev3 = {
  environment: 'dev3',
  base_url: 'https://dev.services.api.viana.ai/',
  base_url_insights: 'https://dev.insights.api.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://07c256f775fd49dd9782beb8fc0adb50@sentry.internal.viana.ai/13'
};

const endpointsPerduePreproduction = {
  environment: 'perdue_ppd',
  base_url: 'https://viana-services-preprod-2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod-perdue.azurewebsites.net/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  //sentry_dsn: 'https://87eb9ff084514f84984d79fc2ceb39c2@sentry.internal.viana.ai/9'
};

const authPreproduction = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'preprod',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/preprod/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '864a614e-590f-4abf-8cf2-ec3b7ebecaab',
  scope: 'openid user',
};

const endpointsPreproduction = {
  environment: 'preproduction',
  base_url: 'https://ppd.services.api.viana.ai/',
  base_url_insights: 'https://viana-insights-preprod.azurewebsites.net/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  //sentry_dsn: ''
};

const authPreproduction3 = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'preprod',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/preprod/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '864a614e-590f-4abf-8cf2-ec3b7ebecaab',
  scope: 'openid user',
};

const endpointsPreproduction3 = {
  environment: 'preproduction',
  base_url: 'https://viana-services-preprod-2-copy3.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod.azurewebsites.net/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://e65b1c8356c04ca08844f50cb427fee3@sentry.internal.viana.ai/15'
};

const authProduction = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user',
};

const endpointsProduction = {
  environment: 'production',
  base_url: 'https://services.api.viana.ai/',
  base_url_insights: 'https://insights.api.viana.ai/',
  admin_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: ''
};


export let auth;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  auth = authProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction3') {
  auth = authPreproduction3;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev2') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev3') {
  auth = authDev;
}  else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging3') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_dev') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_staging') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal_preproduction') {
  auth = authPreproduction;
}
else {
  auth = authStaging;
}

export let endpoints;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  endpoints = endpointsProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  endpoints = endpointsPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction3') {
  endpoints = endpointsPreproduction3;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  endpoints = endpointsDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev2') {
  endpoints = endpointsDev2;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev3') {
  endpoints = endpointsDev3;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  endpoints = endpointsNode16Dev;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  endpoints = endpointsStaging2;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging3') {
  endpoints = endpointsStaging3;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_dev') {
  endpoints = endpointsVersaDev;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_staging') {
  endpoints = endpointsVersaStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal') {
  endpoints = endpointsVianaLite;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal_preproduction') {
  endpoints = endpointsVianaLitePreproduction;
}
else {
  endpoints = endpointsStaging;
}

const initOptions = {
  url: auth.auth_url, realm: auth.realm, clientId: auth.client_id, onLoad: 'login-required'
};

const keycloak = new Keycloak(initOptions);
export default keycloak;
